.rw-select-list {
  overflow: auto;
  position: relative;

  .rw-list {
    max-height: none;
    font-size: $font-size; // use widget font-size
  }
}

.rw-select-list-label {
  display: block;
  position: relative;
  font-weight: normal;
  cursor: inherit;
  padding-left: 20px;
  margin: 0;

  .rw-rtl & {
    padding-left: 0;
    padding-right: 20px;
  }
}

input.rw-select-list-input {
  position: absolute;
  z-index: 10000;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateZ(0);
  top: 0.1em /9;
  margin: 0;
  line-height: normal;
  cursor: inherit;

  .rw-rtl & {
    left: auto;
    right: 0;
  }
}

.rw-loading-mask {
  content: '';
  background: $img-busy-lg no-repeat center;
  position: absolute;
  background-color: #fff;
  border-radius: $input-border-radius;

  @include opacity(0.7);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

// react-select用の .is-valid .is-invalid クラス. bootstrapの諸々を再利用
@mixin react-select-form-validation-state($state, $color, $icon) {
  .react-select-control {
    .was-validated &:#{$state},
    &.is-#{$state} {
      & > div:nth-of-type(1) {
        border-color: $color;

        @if $enable-validation-icons {
          padding: 0 $input-height-inner 0 0;
          background-image: $icon;
          background-repeat: no-repeat;
          background-position: center right $input-height-inner-quarter;
          background-size: $input-height-inner-half $input-height-inner-half;
        }

        &:focus {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
        }

      }
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: block;
      }
    }
  }
}

// bootstrapの _variables.scss, mixins/_form.scss 参照
@each $state, $data in $form-validation-states {
  @include react-select-form-validation-state($state, map-get($data, color), map-get($data, icon));
}

.form-group {
  .required:before {
    content: "*";
    color: $red;
  }
}

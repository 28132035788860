/**
 * Animation
 */
 .is-loading-spin::before {
      -webkit-animation: spin 1.5s linear infinite;
      -moz-animation: spin 1.5s linear infinite;
      -ms-animation: spin 1.5s linear infinite;
      -o-animation: spin 1.5s linear infinite;
      animation: spin 1.5s linear infinite;
      display: inline-block;
  }

  @-webkit-keyframes spin {
    0% {-webkit-transform: rotate(0deg);}
    100% {-webkit-transform: rotate(360deg);}
  }
  @-moz-keyframes spin {
    0% {-moz-transform: rotate(0deg);}
    100% {-moz-transform: rotate(360deg);}
  }
  @-ms-keyframes spin {
    0% {-ms-transform: rotate(0deg);}
    100% {-ms-transform: rotate(360deg);}
  }
  @-o-keyframes spin {
    0% {-o-transform: rotate(0deg);}
    100% {-o-transform: rotate(360deg);}
  }
  @keyframes spin {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
  }

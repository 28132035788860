svg.recharts-surface {
  transform: translate3d(0, 0, 0);
}

.container-fluid {
  transform: translate3d(0, 0, 0);
}

.rc-tabs-top {
  transform: translate3d(0, 0, 0);
}
.recharts-layer.recharts-line {
  transform: translate3d(0, 0, 0);
}

.recharts-layer.recharts-line-dots {
  transform: translate3d(0, 0, 0);
}

.recharts-dot.recharts-line-dot {
  transform: translate3d(0, 0, 0);
}

.recharts-tooltip-wrapper.recharts-tooltip-wrapper-left.recharts-tooltip-wrapper-bottom {
  transform: translate3d(0, 0, 0);
}

.word-cloud > svg {
  transform: translate3d(0, 0, 0);
}
